import type {ReactNode} from 'react'

interface NavItemsProps {
	className?: string
	children: ReactNode
}

export const NavItems: React.FC<NavItemsProps> = ({
	className = '',
	children,
}) => {
	return (
		<div
			className={`lg:max-h-3/4 fixed h-[calc(100vh-138px)] w-full border-b bg-white transition-all duration-200 ease-out lg:static lg:h-auto lg:overflow-y-auto ${className}`}
		>
			{children}
		</div>
	)
}
