import type {ReactNode, ReactElement} from 'react'
import {isValidElement, Children} from 'react'
import cls from 'clsx'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import type {MainNavBorderColor} from './helper'
import {mainNavBorderColors} from './helper'
import {NavItems} from './main-nav-items'

const borderColor = mainNavBorderColors

interface MainNavProps {
	className?: string
	children: ReactNode
	pinned?: boolean
	brandLogo?: ReactNode | null
	logoHrefUrl?: string
	logoHrefInNewWindow?: boolean
	logo?: ReactNode | null
	mobileLogo?: ReactNode | null
	mainBorderColor?: MainNavBorderColor
	extraLogoContent?: ReactNode
}

const MainNav: React.FC<MainNavProps> = ({
	className = '',
	children,
	pinned = false,
	brandLogo = null,
	logoHrefUrl = 'https://www.elanco.com/',
	logoHrefInNewWindow = false,
	logo = null,
	mobileLogo = null,
	mainBorderColor = 'black',
	extraLogoContent = null,
}) => {
	const {isMobile, isTablet} = useWindowSize()
	const childItems = Children.toArray(children)
	const navItems = childItems.filter(
		(x): x is ReactElement => isValidElement(x) && x.type === NavItems
	)

	const shouldCollapse =
		((!isMobile || !isTablet) && pinned) || navItems.length === 0

	const logoToUse = logo || (
		<svg
			className="w-16 fill-current text-primary"
			height="31.73"
			viewBox="0 0 637.38 316.17"
			width="64"
		>
			<title>Elanco Logo</title>
			{/* SVG Path */}
		</svg>
	)

	const mobileLogoToUse = mobileLogo || (
		<svg
			className="mr-4 w-16 fill-current text-primary"
			height="31.73"
			viewBox="0 0 637.38 316.17"
			width="64"
		>
			<title>Elanco Logo</title>
			{/* SVG Path */}
		</svg>
	)

	return (
		<div
			className={cls(
				className,
				'bg-main text-black',
				{
					'border-b border-gray-400 lg:border-b-0 lg:pb-0 lg:pt-3':
						!shouldCollapse,
				},
				'z-100 relative transition-all duration-200 ease-out'
			)}
		>
			<div
				className={`border-b-6 mx-auto mt-2 flex items-center justify-between px-4 pb-2 lg:mt-0 lg:px-8 ${borderColor[mainBorderColor]}`}
			>
				<div className="items-top flex">
					<a
						href={logoHrefUrl}
						rel="noopener"
						target={logoHrefInNewWindow ? '_blank' : undefined}
					>
						{isMobile || isTablet ? mobileLogoToUse : logoToUse}
					</a>
					{isMobile ? brandLogo : null}
					{extraLogoContent}
				</div>
				<div className="flex flex-grow justify-end">
					{childItems.filter(
						(x): x is ReactElement =>
							isValidElement(x) && x.type !== NavItems
					)}
				</div>
			</div>

			{navItems}
		</div>
	)
}

export {MainNav}
